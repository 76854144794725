import styles from "../../../styles/layout/Footer/Footer.module.scss";
import {
  StraightPipes,
  StraightPipesSecond,
  TheCanister,
  StraightPipesFooter,
  StraightPipesSecondFotter,
  DoublePipesFooter,
} from "../../Widgets/WidgetsPipe/Pipes";
import { useEffect, useState } from "react";
import { dataFooter } from "./dataFooter";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
//icon
import iconMap from "../../../assets/icon/map/point-2.svg";
// import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { useWindowSize } from "../../Widgets/hooks/hooks";

const Footer = () => {
  const [endLine, setEndLine] = useState("594px");
  const [coordinatesYm, setCoordinatesYm] = useState({
    x: 43.592409,
    y: 39.999642,
    // x: 43.592409,
    // y: 39.999642,
    zoomY: 10,
    sizeIcon: 1,
  });
  // const { width } = useWindowSize();
  useEffect(() => {
    if (window.screen.width < 840) {
      let nObCor = {
        x: 43.592409,
        y: 39.999642,
        zoomY: 9,
        sizeIcon: 2,
      };
      setCoordinatesYm(() => nObCor);
    }
  }, []);

  // const LOCATION = {
  //   center: [37.588144, 55.733842],
  //   zoom: 9,
  // };

  return (
    <div id="contact" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>{"Контакты".toLocaleUpperCase()}</h1>
      </div>
      <div className={styles.blockDiscript}>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>
            {dataFooter?.titelTimeWork}
          </h1>
          <h2 className={styles.textDiscriptW}>{dataFooter?.timeWork}</h2>
        </div>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>
            {dataFooter?.titelAdress}
          </h1>
          <h2 className={styles.textDiscriptR}>{dataFooter?.adress}</h2>
        </div>
        <div className={styles.blockContentDiscript}>
          <h1 className={styles.textTitelDiscript}>{dataFooter?.titelPhone}</h1>
          <h2 className={styles.textDiscriptR}>{dataFooter?.phone}</h2>
        </div>
      </div>
      <div className={styles.blockImgMap}>
        <div style={{ width: "100%", height: "100%" }}>
          <YMaps>
            <Map
              className={styles.mapStyles}
              // defaultState={{ center: [43.669775, 40.263063], zoom: 12 }}
              defaultState={{
                center: [coordinatesYm.x, coordinatesYm.y],
                zoom: coordinatesYm.zoomY,
              }}
            >
              <Placemark
                defaultGeometry={[43.669775, 40.263063]}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: iconMap,
                  iconImageSize: [
                    96.15 / coordinatesYm.sizeIcon,
                    137.92 / coordinatesYm.sizeIcon,
                  ],
                  iconImageOffset: [
                    -96.15 / coordinatesYm.sizeIcon,
                    -137.92 / coordinatesYm.sizeIcon,
                  ],
                }}
              />
            </Map>
            {/* options={{
                iconLayout: 'default#image',
                iconImageHref: IconGeoPosition,
                iconImageSize: [40, 40],
                iconImageOffset: [100, -10] */}
          </YMaps>
        </div>
      </div>
      <div className={styles.blockEndFooter}>
        <h1 className={styles.textblockEndFooter}>
          Птицы захмелели, {new Date().getFullYear()}
        </h1>
      </div>
    </div>
  );
};

export { Footer };
