import styles from "../../../styles/layout/BlockAtmosphere/Atmosphere.module.scss";
import { dataBar } from "./dataAtmosphere";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
import { useState } from "react";

const Atmosphere = ({ setOpenModal, setOpenPopup }) => {
  // const [openMotive, setOpenMotive] = useState(false);

  return (
    <div id="inter" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h1 className={styles.textTitel}>ПРОНИКНИТЕСЬ АТМОСФЕРОЙ</h1>
      </div>
      <div className={styles.blockDiscript}>
        <h2 className={styles.textDisript}>
          Птицы захмелели ‒ это отдых на любой вкус
        </h2>
        <button
          onClick={() => setOpenPopup(() => true)}
          className={styles.buttonRentTab}
        >
          ЗАБРОНИРОВАТЬ СТОЛ
        </button>
      </div>
      <div className={styles.blockVarintBar}>
        {dataBar.slice(0, 4).map((elem, index) => (
          <div
            key={index}
            onClick={() => setOpenModal(() => true)}
            className={styles.contentVariantBar}
          >
            <img className={styles.imgVariantBar} src={elem?.photo} />
            <div className={styles.blockTextVarintBar}>
              <div className={styles.blockAbsText}>
                <h1 className={styles.textContentVarintBar}>
                  {elem?.titel.toLocaleUpperCase()}
                </h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Atmosphere };
